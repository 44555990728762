import React, { useState, useEffect, useRef } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import {
    Typography,
    TextField,
    Grid,
    Card,
    CardContent,
    Button,
    CardHeader,
    Divider,
    Chip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { saveAs } from 'file-saver';

import DateRangePicker from './components/DateRangePicker';
import Calendario from './components/Calendario';
import CircularProgress from '@material-ui/core/CircularProgress';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const randDarkColor = () => {
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}

function Resumen(props) {
    const { getAccessTokenWithRefresh, validRole } = props;
    const API_DEFAULT = props.API_DEFAULT;
    const theme = props.theme;
    const [accessToken, setAccessToken] = props.accessToken;
    const username = props.username;
    const setShowBackdrop = props.setShowBackdrop;
    const setSnack = props.setSnack;
    const setMessage = props.setMessage;
    const point = props.point;
    const [openEnc, setOpenEnc] = useState(false);
    const [openListEnc, setOpenListEnc] = useState(false);
    const [openEst, setOpenEst] = useState(false);
    const [openListEst, setOpenListEst] = useState(false);

    const [encuestador, setEncuestador] = useState([]);
    const [cedula, setCedula] = useState([]);
    const [estudio, setEstudio] = useState([]);
    //const [centroCostos, setCentroCostos] = useState(0);

    const [generar, setGenerar] = useState(false);

    const [encuestadores, setEncuestadores] = useState([{ cedula: 'TODOS LOS ENCUESTADORES' }]);
    const [estudios, setEstudios] = useState([{ nombre_integra: 'TODOS LOS ESTUDIOS' }]);
    const [eventos, setEventos] = useState([]);

    const refEnc = useRef(null);
    const refEst = useRef(null);

    const [startDate, startDateChange] = useState(new Date());
    const [endDate, endDateChange] = useState(new Date());

    let inputValue = React.useState('');
    const loading = openEnc && encuestadores.length === 0;
    let controller = new AbortController();
    let timeout;

    const fetchEncuestadores = async (access = accessToken) => {

        if (inputValue.length > 0) {

            const res = await fetch(`${API_DEFAULT}/productividad/lista_personal/tipo_campo=0/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadores(newAccess);
                        }

                    } else {
                        res.push({'cedula': 'TODOS LOS ENCUESTADORES', 'nombre':'TODOS LOS ENCUESTADORES', 'cargo': 'No aplica'});
                        setEncuestadores(res.sort((first, second) => (first['cedula'] - second['cedula'])));
                        fetchEncuestadoresCualitativo();
                        setShowBackdrop(false);
                    }
                });
            }
            else {
                setShowBackdrop(false);
                
            }
        }
    }

    const fetchEncuestadoresCualitativo = async (access = accessToken) => {

        if (inputValue.length > 0) {

            const res = await fetch(`${API_DEFAULT}/productividad/lista_personal/tipo_campo=4/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadoresCualitativo(newAccess);
                        }

                    } else {
                        res.sort((first, second) => (first['cedula'] - second['cedula'])).forEach((element) => {
                            setEncuestadores(old => [...old, element]);
                        });
                        
                        setShowBackdrop(false);
                    }
                });
            }
            else {
                setShowBackdrop(false);
                
            }
        }
    }

    const fetchEstudios = async (access = accessToken) => {
        const res = await fetch(`${API_DEFAULT}/productividad/lista_estudios/tipo_campo=0/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchEstudios(newAccess);
                }
            }
            else {
                const aux = estudios.concat(res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades'));
                setEstudios(aux);
                fetchEstudiosCualitativo();
            }
        });
    }

    const fetchEstudiosCualitativo = async (access = accessToken) => {
        const res = await fetch(`${API_DEFAULT}/productividad/lista_estudios/tipo_campo=4/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchEstudiosCualitativo(newAccess);
                }
            }
            else {
                res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades')
                .forEach((element)=>{
                    setEstudios(old => [...old, element]);
                });
                
                
            }
        });
    }

    const generarResumen = async (access = accessToken) => {
        setShowBackdrop(true);
        console.log(cedula);

        const res = await fetch(`${API_DEFAULT}/productividad/resumen/tipo_campo=${estudio.tipo_campo !== undefined?estudio.tipo_campo:0}&cedula=${cedula}&centro_costos=${estudio}&fecha_ini=${startDate.toISOString().split('T')[0]}&fecha_fin=${endDate.toISOString().split('T')[0]}&descargar=${false}/`, {
            headers: { 'Authorization': 'Bearer ' + access },
            
        })
        console.log(res)
        ;
        console.log('cedulaaaaaaaaaaaaaa')
        console.log(cedula)

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    generarResumen(newAccess);
                }
            }
            else {
                var aux = [];
                var aux2 = [];
                var id = 1;
                const group = res.reduce((r, { estudio, start, end, cedula, nombre, title, tipo_actividad }) => {
                    cedula = cedula + ': ' + nombre
                    let e = {
                        id: id,
                        title: estudio,
                        end: end,
                        start: start,
                        actividades: [],
                        encuestadores: []
                    }
                    if (!r.map(r => [r.title, r.start]).some(v => v[0] === estudio && v[1] === start)) {
                        r[r.length] = e;
                        aux[[estudio, start]] = r.length - 1;
                        id = id + 1
                    }
                    if (r[aux[[estudio, start]]].actividades[cedula] === undefined) {
                        r[aux[[estudio, start]]].encuestadores.push(cedula)
                        r[aux[[estudio, start]]].actividades[cedula] = []
                    }
                    if (aux2[estudio] === undefined) {
                        aux2[estudio] = randDarkColor();
                    }
                    r[aux[[estudio, start]]].actividades[cedula].push({
                        title: title,
                        actividad: tipo_actividad === undefined ? 'E' : tipo_actividad
                    });
                    r[aux[[estudio, start]]].color = aux2[estudio];
                    r[aux[[estudio, start]]].color2 = 'white';
                    return r;
                }, []);
                setEventos(group);
                setShowBackdrop(false);
                setGenerar(true);
            }
        });
    }

    const descargarResumen = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/productividad/resumen/tipo_campo=${estudio.tipo_campo !== undefined?estudio.tipo_campo:0}&cedula=${cedula}&centro_costos=${estudio}&fecha_ini=${startDate.toISOString().split('T')[0]}&fecha_fin=${endDate.toISOString().split('T')[0]}&descargar=${true}/`, {
            headers: { 'Authorization': 'Bearer ' + access },
        });

        if (res.ok) {
            const date = new Date().toISOString().split('T')[0]
            const filename = `Resumen reporte de productividad presencial ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        descargarResumen(newAccess);
                    }
                }
            });
        }
    }

    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchEncuestadores();
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    useEffect(() => {
        if (validRole) {
            fetchEncuestadores();
            fetchEstudios();
        }
    }, [validRole]) // eslint-disable-line react-hooks/exhaustive-deps

    const limpiar = () => {
        setGenerar(false);
        setEncuestador([]);
        setCedula([]);
        setEstudio([]);
        setEventos([]);
        startDateChange(new Date());
        endDateChange(new Date());
    }

    return (
        <Card >
            <CardHeader
                title={
                    <Typography variant='h6'>Vista de Resumen</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Seleccione el(los) encuestador(es):</Typography>
                        <Autocomplete
                            disabled={generar}
                            multiple
                            filterSelectedOptions={true}
                            open={openListEnc}
                            onFocus={() => !openEnc ? setOpenEnc(true) : null}
                            onOpen={() => { if (!openEnc) setOpenEnc(true); if (!openListEnc) setOpenListEnc(true) }}
                            onClose={() => { if (encuestador.length < 1) setOpenEnc(false); setOpenListEnc(false) }}
                            onBlur={() => setOpenEnc(false)}
                            limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                            size='small'
                            value={encuestador}
                            loading={loading}
                            onChange={(event, value) => {
                                console.log('onchange')
                                console.log({event,value})
                                if (value[0] === 'TODOS LOS ENCUESTADORES') {
                                    setCedula(['TODOS LOS ENCUESTADORES'])
                                }
                                
                                else if (value.length > 0) {
                                    let newCedula = []
                                    for (var i = 0; i < value.length; i++) {
                                        newCedula.push(parseInt(value[i].split(': ')[0]));
                                    }
                                    setCedula(newCedula);
                                }
                                else {
                                    setCedula([]);
                                }
                                setEncuestador(value);
                                setTimeout(() => { refEnc.current.scrollTo(0, window.innerHeight) }, 0);
                            }}
                            options={
                                encuestador.length === 0 ?
                                    encuestadores.map(option => option.cedula === 'TODOS LOS ENCUESTADORES' ? 'TODOS LOS ENCUESTADORES' : '' + option.cedula + ': ' + option.nombre)
                                    :
                                    encuestador.length === 1 && encuestador[0] === 'TODOS LOS ENCUESTADORES' ?
                                    []
                                    :
                                    encuestadores.slice(1).map(option => '' + option.cedula + ': ' + option.nombre) 
                                    // console.log(encuestadores.slice(1).map(option => '' + option.cedula + ': ' + option.nombre))
                                    
                            }
                            noOptionsText='No hay coincidencias'
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        style={{ overflow: 'hidden' }}
                                        variant="outlined"
                                        label={option}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderOption={(option) => {
                                if (option === 'TODOS LOS ENCUESTADORES')
                                    return (<span style={{ fontWeight: 500 }}>{option}</span>);
                                else
                                    return (<span>{option}</span>);
                            }}
                            renderInput={params => {
                                const { inputProps, InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;

                                return (
                                    <TextField
                                        {...restParams}
                                        size="small"
                                        InputProps={{
                                            onKeyDown: (e) => {
                                                if (e.key === 'ArrowLeft') {
                                                    e.stopPropagation()
                                                } else {
                                                    e.persist();
                                                    clearTimeout(timeout);

                                                    timeout = setTimeout(() => {
                                                        inputValue = e.target.value;
                                                        handleChange();
                                                        clearTimeout(timeout);
                                                    }, 700);
                                                }
                                            },
                                            ...restInputProps,
                                            startAdornment: (
                                                <div ref={refEnc} onClick={() => { if (!openListEnc) setOpenListEnc(true); if (!openEnc) setOpenEnc(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: openEnc ? 'auto' : null, display: openEnc ? null : 'flex', flexDirection: openEnc ? null : 'row' }}>
                                                    {startAdornment}
                                                </div>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),

                                        }}
                                        inputProps={{ ...inputProps, style: { position: openEnc || encuestador.length === 0 ? null : 'absolute', width: loading ? 'calc(100% - 28px)' : 'calc(100% - 20px)', zIndex: openEnc || encuestador.length === 0 ? null : -1 } }}
                                        variant="outlined"
                                        autoFocus={true}
                                        fullWidth
                                        helperText={encuestador.length > 1 ? 'Nota: Recuerde que cuando se selecciona varios encuestadores, las actividades que aparecen en el calendario son las que tienen los seleccionados en común.' : null}
                                    />

                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Seleccione el(los) estudio(s):</Typography>
                        <Autocomplete
                            disabled={generar}
                            multiple
                            filterSelectedOptions={true}
                            open={openListEst}
                            onFocus={() => !openEst ? setOpenEst(true) : null}
                            onOpen={() => { if (!openEst) setOpenEst(true); if (!openListEst) setOpenListEst(true) }}
                            onClose={() => { if (estudio.length < 1) setOpenEst(false); setOpenListEst(false) }}
                            onBlur={() => setOpenEst(false)}
                            limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                            size='small'
                            value={estudio}
                            onChange={(event, value) => {
                                setEstudio(value);
                                setTimeout(() => { refEst.current.scrollTo(0, window.innerHeight) }, 0);
                            }}
                            options={
                                estudio.length === 0 ?
                                    estudios.map(option => option.nombre_integra)
                                    :
                                    estudio.length === 1 && estudio[0] === 'TODOS LOS ESTUDIOS' ?
                                        []
                                        :
                                        // console.log()
                                        estudios.slice(1).map(option => option.nombre_integra)
                            }
                            
                            noOptionsText='No hay coincidencias'
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        style={{ overflow: 'hidden' }}
                                        variant="outlined"
                                        label={option}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderOption={(option) => {
                                if (option === 'TODOS LOS ESTUDIOS')
                                    return (<span style={{ fontWeight: 500 }}>{option}</span>);
                                else
                                    return (<span>{option}</span>);
                            }}
                            renderInput={params => {
                                const { inputProps, InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;

                                return (
                                    <TextField
                                        {...restParams}
                                        size="small"
                                        InputProps={{
                                            onKeyDown: (e) => { if (e.key === 'ArrowLeft') e.stopPropagation() },
                                            ...restInputProps,
                                            startAdornment: (
                                                <div ref={refEst} onClick={() => { if (!openListEst && !generar) setOpenListEst(true); if (!openEst && !generar) setOpenEst(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: openEst ? 'auto' : null, display: openEst ? null : 'flex', flexDirection: openEst ? null : 'row' }}>
                                                    {startAdornment}
                                                </div>
                                            )
                                        }}
                                        inputProps={{ ...inputProps, style: { position: openEst || estudio.length === 0 ? null : 'absolute', width: 'calc(100% - 20px)', zIndex: openEst || estudio.length === 0 ? null : -1 } }}
                                        variant="outlined"
                                        fullWidth
                                    />

                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs>
                        <Typography variant='body1'>Seleccione el rango de fechas:</Typography>
                        <DateRangePicker
                            disabled={generar}
                            theme={theme}
                            start={[startDate, startDateChange]}
                            end={[endDate, endDateChange]}
                        />
                    </Grid>
                    <Grid item xs={point === 'sm' || point === 'xs' ? 12 : null}>
                        <Grid container
                            direction='row'
                            justify={point === 'sm' || point === 'xs' ? 'flex-end' : null}
                        >
                            <Button
                                size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                disabled={encuestador.length === 0 || estudio.length === 0 || generar}
                                style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1) }}
                                variant='contained' color='primary'
                                onClick={() => { generarResumen() }}
                            >
                                Generar
                            </Button>
                            <ThemeProvider theme={themeDownloadButton}>
                                <Button
                                    size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                    style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1) }}
                                    disabled={encuestador.length === 0 || estudio.length === 0}
                                    variant='contained' color='primary'
                                    onClick={() => descargarResumen()}>
                                    Descargar
                                </Button>
                            </ThemeProvider>
                            <Button
                                size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                disabled={encuestador.length === 0 && estudio.length === 0 && startDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] && endDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]}
                                style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3) }}
                                variant='contained' color='secondary'
                                onClick={() => { limpiar() }}
                            >
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    generar ?
                        <div style={{ marginTop: theme.spacing(3) }} className='Calendario'>
                            <Calendario
                                theme={theme}
                                username={username}
                                eventos={eventos}
                                encuestador={encuestador}
                                dates={[startDate, endDate]}
                                setSnack={setSnack}
                                setMessage={setMessage}
                            />
                        </div>
                        :
                        null
                }
            </CardContent>
        </Card>

    );
}

export default Resumen;