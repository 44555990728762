import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    Container,
    IconButton,
    Button,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import NumberFormat from 'react-number-format';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';

import DialogoCrear from './components/DialogoCrear';
import DialogoPerfil from './components/DialogoPerfil';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";



const useStyles = makeStyles({
    table: {
        minWidth: 800,
    },
    menuItem: {
        fontSize: 14
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#2E7D32'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});

themeDownloadButton = responsiveFontSizes(themeDownloadButton);

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function TablaUsuarios(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        validRole,
        correoUser,
        setShowBackdrop,
        setMessage,
        setSeverity,
        setSnack } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const [openCrear, setOpenCrear] = useState(false);
    const [openPerfil, setOpenPerfil] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [datos, setDatos] = useState([]);
    const [count] = useState(0);
    const [filtering, setFiltering] = useState(false);
    const [filtros, setFiltros] = useState(Array(6).fill(''));

    const clearDisabled = !filtros.some((v, i) => {
        if (i < 4 && v !== '') return true;
        else if (i === 5 && (v !== 'Todos' && v !== '')) return true;
        return false;
    });

    const fetchUsuarios = async (access = accessToken) => {
        setShowBackdrop(true);
        if (validRole) {
            const res = await fetch(`${API_DEFAULT}/legalizaciones/usuarios_legalizaciones/?cedula=${filtros[0] === '' ? 'all-elements' : filtros[0]}&nombre=${filtros[1] === '' ? 'all-elements' : filtros[1]}&usuario=${filtros[2] === '' ? 'all-elements' : filtros[2]}&fecha=${filtros[3] === '' ? 'all-elements' : filtros[3]}&creado_por=${filtros[4] === '' ? 'all-elements' : filtros[4]}&estado=${filtros[5] === 'Todos' || filtros[5] === '' ? 'all-elements' : filtros[5]}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
            });

            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        fetchUsuarios(newAccess);
                    }

                } else {
                    setDatos(res);
                    setShowBackdrop(false);
                }
            });

        }

    }

    const clearFilters = () => {
        for (let i = 0; i < 5; i++) {
            filtros[i] = '';
        }

        filtros[5] = 'Todos';

        setFiltros([...filtros]);
    }

    const activarUsuario = async (cedula, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/legalizaciones/usuarios_legalizaciones/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'cedula': cedula,
                'estado': 1
            })
        });

        if (res.ok) {
            fetchUsuarios(access);
            setMessage('Se ha actualizado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        activarUsuario(cedula, newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const desactivarUsuario = async (cedula, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/legalizaciones/usuarios_legalizaciones/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'cedula': cedula,
                'estado': 0
            })
        });

        if (res.ok) {
            fetchUsuarios(access);
            setMessage('Se ha actualizado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        activarUsuario(cedula, newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const handleDateChange = (date)=>{
        filtros[3] = date.getFullYear()+'-'+((date.getMonth() < 10)?'0'+(date.getMonth()+1):(date.getMonth()+1))+'-'+((date.getDate() < 10)? '0'+(date.getDate()):(date.getDate()));
        setFiltros([...filtros]);
    }
    
    useEffect(() => { filtros[5] = 'Todos'; setFiltros([...filtros]) }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchUsuarios()} }, [validRole, page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='Table-body'>
            <Container component='main' maxWidth='xl'>
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                        classes={{
                            title: classes.cardTitle
                        }}
                        title={
                            <React.Fragment>
                                <Typography variant='h6'>Tabla de Usuarios</Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button size='small' variant='contained' color='primary'
                                        onClick={() => { if (correoUser === '') {setOpenPerfil(true)} else {setOpenCrear(true)} }}>
                                        Crear Usuario</Button>
                                </div>
                            </React.Fragment>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <TableContainer component={Paper} variant='outlined' style={{ borderBottom: '0px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'></TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Cédula</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Nombre</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Usuario</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Fecha de creación</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Creado por</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Estado</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='center'>
                                            <div style={{ display: 'flex' }}>
                                                <ThemeProvider theme={themeDownloadButton}>
                                                    <IconButton
                                                        style={{ marginRight: 4 }}
                                                        size='small'
                                                        color='primary'
                                                        disabled={clearDisabled || filtering}
                                                        onClick={() => {
                                                            setPage(0);
                                                            setFiltering(true);
                                                        }}
                                                    >
                                                        <CheckIcon />
                                                    </IconButton>
                                                </ThemeProvider>
                                                <IconButton
                                                    size='small'
                                                    color='secondary'
                                                    onClick={() => {
                                                        clearFilters();
                                                        if (filtering) { setPage(0); setFiltering(false); }
                                                    }}
                                                    disabled={clearDisabled}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[0]}
                                                onValueChange={(e) => { filtros[0] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[1]}
                                                onChange={(e) => { filtros[1] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[2]}
                                                onChange={(e) => { filtros[2] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    onFocus={(e) => e.target.select()}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 14,
                                                            padding: '7.69px 9.69px',
                                                            textAlign: 'right'
                                                        }
                                                    }}
                                                    value={(filtros[3] === '') ? undefined: filtros[3]+'T00:00:00-05:00'}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    disabled={filtering}
                                                    format="yyyy-MM-dd"
                                                    onChange={handleDateChange}
                                                    disableFuture
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[4]}
                                                onChange={(e) => { filtros[4] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            <Autocomplete
                                                value={filtros[5]}
                                                onChange={(e,v)=>{console.log(v); filtros[5] = v; setFiltros([...filtros]);}}
                                                options={
                                                    ['Todos', 'Activo', 'Inactivo'].map(option => option)
                                                }
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {datos.map((row) => (
                                        <TableRow key={row.cedula}>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} component='th' scope='row' align='center'>
                                                <Typography style={{ color: '#1565C0', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }} onClick={() => { if (!filtering) { clearFilters(); if (row.estado === 'Activo') { desactivarUsuario(row.cedula) } else { activarUsuario(row.cedula) } } }}>{(row.estado === 'Activo') ? 'Desactivar' : 'Activar'}</Typography>
                                            </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'> {row.cedula} </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 200 }} align='left'>{row.nombre}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 200 }} align='left'>{row.username}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 200 }} align='right'>{row.fecha_creado.split(' ')[0]}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 200 }} align='left'>{row.creador_usuario}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.estado}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component={Paper}
                            variant='outlined'
                            style={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={(e, n) => { if (!filtering) { clearFilters() } setPage(n) }}
                            onChangeRowsPerPage={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(0) }}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                            ActionsComponent={TablePaginationActions}
                        />

                    </CardContent>
                </Card>
            </Container>
            <DialogoCrear
                theme={theme}
                open={[openCrear, setOpenCrear]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                setShowBackdrop={setShowBackdrop}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                fetchUsuarios={fetchUsuarios}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
            />
            <DialogoPerfil
                open={[openPerfil, setOpenPerfil]}
            />
        </div>
    );
}

export default TablaUsuarios;