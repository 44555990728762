import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@material-ui/core';


function DialogoPerfil(props) {
    const [open, setOpen] = props.open;
    return (
            <Dialog
                open={open}
            >
                <DialogTitle>{"Actualiza tus datos"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Para crear usuarios es necesario que tengas el correo electrónico actualizado en nuestro sistema. Para actualizarlo, dirígete a tu perfil y añádelo.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setOpen(false)}} color="primary">
                        Cancelar
                    </Button>
                    <Button href="https://perfil.desarrolloscnc.com/inicio-sesion" color="primary" autoFocus>
                        Ir a perfil
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default DialogoPerfil;