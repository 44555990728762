import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    TextField,
    Grid,
    Card,
    CardContent,
    Button,
    CardHeader,
    Divider,
    Chip,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HelpIcon from '@material-ui/icons/Help';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import Calendario from './components/Calendario';
import actividades from '../../data/actividades';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    tooltip: {
        margin: 0,
    },
}));

const randDarkColor = () => {
    var lum = -0.25;
    var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    var rgb = "#",
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
    }
    return rgb;
}

function Reporte(props) {
    const { getAccessTokenWithRefresh, validRole } = props;
    const classes = useStyles();
    const API_DEFAULT = props.API_DEFAULT;
    const theme = props.theme;
    const [accessToken, setAccessToken] = props.accessToken;
    const username = props.username;
    const setShowBackdrop = props.setShowBackdrop;
    const setSnack = props.setSnack;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;
    const point = props.point;
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [encuestador, setEncuestador] = useState([]);
    const [cedula, setCedula] = useState([]);
    const [estado, setEstado] = useState('Trabajo normal');
    const [estudio, setEstudio] = useState(null);
    const [tipoCampo, setTipoCampo] = useState('');
    const [tipoContrato, setTipoContrato] = useState('');
    const [actividad, setActividad] = useState(null);
    const [idActividad, setIdActividad] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [remunerada, setRemunerada] = useState(false);
    const [nocturna, setNocturna] = useState(false);
    const [tarifa, setTarifa] = useState('');
    const [justificacion, setJustificacion] = useState('');
    const [isJustificationRequired, setIsJustificationRequired] = useState(false);
    const [descripcionError, setDescripcionError] = useState(false);
    const [tarifaError, setTarifaError] = useState(false);
    const [registrar, setRegistrar] = useState(false);
    const [mes, setMes] = useState((new Date()).getMonth() + 1);
    const [anio, setAnio] = useState((new Date()).getFullYear());
    const [customData, setCustomData] = useState([]);

    const [encuestadores, setEncuestadores] = useState([]);
    const [estudios, setEstudios] = useState([]);
    const [eventos, setEventos] = useState([]);

    const [colorEstudios, setColorEstudios] = useState({});
    const [openTooltip, setOpenTooltip] = useState(false);

    let inputValue = React.useState('');
    const refEnc = useRef(null);

    const [searching, setSearching] = useState(false);
    const loading = open && encuestadores.length === 0 && searching;
    
    let controller = new AbortController();
    let timeout;

    const fetchEncuestadores = async (access = accessToken) => {
        
        if (inputValue.length > 0) {
            setSearching(true);
            const res = await fetch(`${API_DEFAULT}/productividad/lista_personal/tipo_campo=0,4/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadores(newAccess);
                        }

                    } else {
                        setEncuestadores(res.sort((first, second) => (first['cedula'] - second['cedula'])));
                        setShowBackdrop(false);
                        setSearching(false);
                    }
                });
            }else{
                setSearching(false);
                setEncuestadores([]);
            }
        }else{
            setSearching(false);
            setEncuestadores([]);
        }   
    }

    const fetchEstudios = async (access = accessToken) => {
        const res = await fetch(`${API_DEFAULT}/productividad/lista_estudios/tipo_campo=0,2,4/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchEstudios(newAccess);
                }
            }
            else {
                setEstudios(res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades'));
            }
        });
    }

    const fetchInfoEnc = async (access = accessToken) => {
        if (cedula.length === 1) {
            const res = await fetch(`${API_DEFAULT}/productividad/informacion_personal/cedula=${cedula[0]}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            });
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        fetchInfoEnc(newAccess);
                    }
                }
                else {
                    setTipoCampo(res.tipo_campo);
                    setTipoContrato(res.tipo_contrato);
                }
            });
        }
        else if (cedula.length > 1) {
            setTipoCampo('Más de un encuestador seleccionado');
            setTipoContrato('Más de un encuestador seleccionado')
        }
        else {
            setTipoCampo('');
            setTipoContrato('')
        }
    }

    const fetchInfoEventos = async (access = accessToken) => {
        if (cedula.length > 0 && (estudio !== null || estado !== 'Trabajo normal') && idActividad !== '' && !open) {
            setShowBackdrop(true);
            const res = await fetch(`${API_DEFAULT}/productividad/informacion_reporte/tipo_campo=0&cedula=${cedula}&mes=${mes}&anio=${anio}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
            });
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        fetchInfoEventos(newAccess);
                    }
                }
                else {
                    let aux = [];
                    let id2 = 1;
                    const group = res.reduce((r, { estudio, start, end, cedula, nombre, title, tipo_actividad, id, creador, valor }) => {
                        cedula = cedula + ': ' + nombre
                        let e = {
                            id: id2,
                            title: estudio,
                            end: end,
                            start: start,
                            actividades: [],
                            encuestadores: []
                        }
                        if (!r.map(r => [r.title, r.start]).some(v => v[0] === estudio && v[1] === start)) {
                            r[r.length] = e;
                            aux[[estudio, start]] = r.length - 1;
                            id2 = id2 + 1
                        }
                        if (r[aux[[estudio, start]]].actividades[cedula] === undefined) {
                            r[aux[[estudio, start]]].encuestadores.push(cedula)
                            r[aux[[estudio, start]]].actividades[cedula] = []
                        }
                        if (colorEstudios[estudio] === undefined) {
                            colorEstudios[estudio] = randDarkColor();
                            setColorEstudios({ ...colorEstudios });
                        }
                        r[aux[[estudio, start]]].actividades[cedula].push({
                            title: title,
                            actividad: tipo_actividad === undefined ? 'E' : tipo_actividad,
                            creador: creador,
                            id: id,
                            valor: valor
                        });
                        r[aux[[estudio, start]]].color = colorEstudios[estudio];
                        r[aux[[estudio, start]]].color2 = 'white';
                        return r;
                    }, []);

                    setEventos(group);
                    setRegistrar(true);
                    setShowBackdrop(false);
                }
            });
        }
        else {
            setEventos([]);
            setRegistrar(false);
        }
    }

    const fetchCustomOpen = async (access = accessToken) => {
        
        if (inputValue.length > 0) {
            setSearching(true);
            const res = await fetch(`${API_DEFAULT}/productividad/custom_consult/?apk=1`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchCustomOpen(newAccess);
                        }

                    } else {
                        setCustomData(res.sort((first, second) => (first['username'] - second['username'])));
                        setShowBackdrop(false);
                        setSearching(false);
                    }
                });
            }else{
                setSearching(false);
                setCustomData([]);
            }
        }else{
            setSearching(false);
            setCustomData([]);
        }
        
    }

    const sendEvento = async (fecha, horas, access = accessToken) => {
        if ((['XX', 'CCI', 'ECP', 'TRP', 'BNS', 'RCS', 'IMP'].includes(idActividad) && (descripcion === '' || tarifa === '' || tarifa === '0')) || (idActividad === 'NOV' && descripcion === '') || (idActividad === 'TRP' && justificacion === '' && isJustificationRequired)) {
            if (descripcion === '') setDescripcionError(true);
            if ((tarifa === '' || tarifa === '0') && idActividad !== 'NOV') setTarifaError(true);
            setMessage('No se puede crear el evento si existen campos sin diligenciar.')
            setSeverity('error');
            setSnack(true);
        }
        
        else {
            setShowBackdrop(true);
            const cuerpo = []
            for (var i = 0; i < cedula.length; i++) {
                cuerpo.push({
                    'personal': cedula[i],
                    'estudio': estado !== 'Trabajo normal' ? estado : estudio,
                    'tipo_actividad': idActividad,
                    'tipo_campo': estudio.tipo_campo,
                    'fecha': fecha,
                    'horas': horas,
                    'nocturna': nocturna,
                    'descripcion': idActividad === 'CCI' || idActividad === 'ECP' ?
                        {
                            'descripcion': descripcion.charAt(0).toUpperCase() + descripcion.substr(1).toLocaleLowerCase(),
                            'valor_encuesta': parseFloat(tarifa)
                        }
                        :
                        (idActividad === 'NOV' ?
                            {
                                'descripcion': descripcion.charAt(0).toUpperCase() + descripcion.substr(1).toLocaleLowerCase(),
                                'remunerada': remunerada
                            }
                            :
                            (idActividad === 'XX' ?
                                {
                                    'descripcion': descripcion.charAt(0).toUpperCase() + descripcion.substr(1).toLocaleLowerCase(),
                                    'tarifa': parseFloat(tarifa)
                                }
                                :
                                (idActividad === 'BNS' || idActividad === 'RCS' || idActividad === 'IMP' ?
                                    {
                                        'descripcion': descripcion.charAt(0).toUpperCase() + descripcion.substr(1).toLocaleLowerCase(),
                                        'tarifa': parseFloat(tarifa)
                                    }
                                    :
                                    (idActividad === 'TRP' ?
                                    {
                                        'descripcion': descripcion.charAt(0).toUpperCase() + descripcion.substr(1).toLocaleLowerCase(),
                                        'tarifa': parseFloat(tarifa),
                                        'justificacion': justificacion
                                    }                                    
                                    :
                                    {}                                    
                                    )
                                )
                            )
                        )
                })
            }
            const res = await fetch(`${API_DEFAULT}/productividad/actividades/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                body: JSON.stringify(cuerpo)
            });
            if (res.status === 200) {
                fetchInfoEventos(access);
            }
            else {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);

                            sendEvento(fecha, horas, newAccess);
                        }
                    }
                });
            }
        }
    }

    const changeEvento = async (id, horas, access = accessToken) => {
        setShowBackdrop(true);
        let ids = []
        if (id.length === undefined) {
            ids.push(id)
        }
        else {
            ids = id
        }
        const res = await fetch(`${API_DEFAULT}/productividad/actividades/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': ids,
                'horas': horas
            })
        })
        if (res.status === 200) {
            fetchInfoEventos(access);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        changeEvento(id, horas, newAccess);
                    }
                }
            });
        }
    }

    const deleteEvento = async (id, access = accessToken) => {
        setShowBackdrop(true);
        let ids = []
        if (id.length === undefined) {
            ids.push(id)
        }
        else {
            ids = id
        }

        const res = await fetch(`${API_DEFAULT}/productividad/actividades/`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': ids,
            })
        });
        if (res.status === 204) {
            fetchInfoEventos(access);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        deleteEvento(id, newAccess);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (validRole) {

            if (!loading) {
                return undefined;
            }
        }

    }, [validRole, loading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchInfoEnc();
        fetchEstudios();
        fetchCustomOpen();
    }, [cedula]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchInfoEventos();
        fetchCustomOpen();
    }, [cedula, estudio, idActividad, mes, anio, open]) // eslint-disable-line react-hooks/exhaustive-deps

    const limpiar = () => {
        setRegistrar(false);
        setEncuestador([]);
        setCedula([]);
        setEstado('Trabajo normal');
        setEstudio(null);
        setActividad(null);
        setIdActividad('');
        setEventos([]);
        setDescripcion('');
        setDescripcionError(false);
        setRemunerada(false);
        setNocturna(false);
        setTarifa('');
        setTarifaError(false);
        setJustificacion('');
    }

    useEffect(() => {
        if (!open) {
            setEncuestadores([]);
        }
    }, [open]);

    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchEncuestadores();
            
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    return (
        <Card >
            <CardHeader
                title={
                    <Typography variant='h6'>Reporte de Productividad</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={12} xs={12}>
                        <Typography variant='body1'>Seleccione el(los) encuestador(es):</Typography>
                        <Autocomplete
                            multiple
                            filterSelectedOptions={true}
                            open={openList}
                            onFocus={() => !open ? setOpen(true) : null}
                            onOpen={() => { if (!open) setOpen(true); if (!openList) setOpenList(true) }}
                            onClose={() => { if (encuestador.length < 1) setOpen(false); setOpenList(false) }}
                            onBlur={() => setOpen(false)}
                            limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                            size='small'
                            value={encuestador}
                            loading={loading}
                            onChange={(event, value) => {
                                setRegistrar(false);
                                setEncuestador(value);
                                setMes((new Date()).getMonth() + 1);
                                setAnio((new Date()).getFullYear());
                                setTimeout(() => { refEnc.current.scrollTo(0, window.innerHeight) }, 0);
                                if (value.length > 0) {
                                    let newCedula = []
                                    for (var i = 0; i < value.length; i++) {
                                        newCedula.push(parseInt(value[i].split(': ')[0]));
                                    }
                                    setCedula(newCedula);
                                }
                                else {
                                    setCedula([]);
                                }
                            }}
                            options={
                                encuestadores.map(option => '' + option.cedula + ': ' + option.nombre)
                            }
                            noOptionsText={'No hay coincidencias'}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        style={{ overflow: 'hidden' }}
                                        variant="outlined"
                                        label={option}
                                        size="small"
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={params => {
                                const { inputProps, InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;

                                return (
                                    <TextField
                                        {...restParams}
                                        size="small"
                                        value=""
                                        InputProps={{
                                            onKeyDown: (e) => {
                                                if (e.key === 'ArrowLeft') {
                                                    e.stopPropagation()
                                                } else {
                                                    e.persist();
                                                    clearTimeout(timeout);
                                                    timeout = setTimeout(() => {
                                                        inputValue = e.target.value;
                                                        handleChange();
                                                        clearTimeout(timeout);
                                                    }, 700);
                                                }
                                            },
                                            ...restInputProps,
                                            startAdornment: (
                                                <div ref={refEnc} onClick={() => { if (!openList) setOpenList(true); if (!open) setOpen(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: open ? 'auto' : null, display: open ? null : 'flex', flexDirection: open ? null : 'row' }}>
                                                    {startAdornment}
                                                </div>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),

                                        }}
                                        inputProps={{ ...inputProps, style: { position: open || encuestador.length === 0 ? null : 'absolute', width: loading ? 'calc(100% - 28px)' : 'calc(100% - 20px)', zIndex: open || encuestador.length === 0 ? null : -1 } }}
                                        variant="outlined"
                                        autoFocus={true}
                                        fullWidth
                                        helperText={encuestador.length > 1 ? 'Nota: Recuerde que cuando se selecciona varios encuestadores, las actividades que aparecen en el calendario son las que tienen los seleccionados en común.' : null}
                                    />

                                )
                            }}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Typography variant='body1'>Tipo de campo:</Typography>
                        <TextField
                            style={{ backgroundColor: encuestador.length === 0 ? '#E0E0E0' : null, borderRadius: 4 }}
                            inputProps={{
                                readOnly: true,
                            }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={tipoCampo}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Typography variant='body1'>Tipo de contrato:</Typography>
                        <TextField
                            style={{ backgroundColor: encuestador.length === 0 ? '#E0E0E0' : null, borderRadius: 4 }}
                            inputProps={{
                                readOnly: true,
                            }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={tipoContrato}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Estado:</Typography>
                        <Autocomplete
                            style={{ backgroundColor: encuestador.length === 0 ? '#E0E0E0' : null, borderRadius: 4 }}
                            disabled={true}
                            value={estado}
                            disableClearable
                            onChange={(event, value) => {
                                if (value !== null)
                                    setEstado(value)
                                setEstudio(null)
                            }}
                            options={
                                [
                                    { estado: "Trabajo normal" },
                                    { estado: "Vacaciones" },
                                    { estado: "Descuentos" },
                                    { estado: "Calamidades" }
                                ].map(option => option.estado)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={5}>
                        <Typography variant='body1'>Seleccione el estudio:</Typography>
                        <Autocomplete
                            disabled={estado !== 'Trabajo normal'}
                            value={estudio}
                            onChange={(event, value) => {
                                setEstudio(value);
                                /* if (value !== null) {
                                    setCentroCostos(estudios.filter(e => e.nombre_integra === value)[0].centro_costos);
                                }
                                else {
                                    setCentroCostos(0);
                                } */
                            }}
                            options={
                                estudios.map(option => option.nombre_integra)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs md>
                        <Typography variant='body1'>Seleccione la actividad{point !== 'xs' ? ' a registrar' : null}:</Typography>
                        <Grid container
                            direction='row'
                            alignItems='center'
                            spacing={1}
                        >
                            <Grid item sm={['CCI', 'ECP', 'TRP', 'BNS', 'RCS', 'IMP'].includes(idActividad) ? 8 : (['NOV', 'XX'].includes(idActividad) ? 4 : (idActividad === 'COM' ? true : 12))} xs={idActividad === 'COM' ? true : 12}>
                                <Grid container
                                    direction='row'
                                    alignItems='center'
                                    spacing={1}
                                >
                                    <Grid item xs>
                                        <Autocomplete
                                            value={actividad}
                                            onChange={(event, value) => {
                                                setActividad(value);
                                                if (value !== null && value.split(': ')[0] === 'CCI') {
                                                    setDescripcion('Cita cliente incógnito');
                                                }
                                                else if (value !== null && value.split(': ')[0] === 'ECP') {
                                                    setDescripcion('Encuesta campo presencial');
                                                }
                                                else if (value !== null && value.split(': ')[0] === 'TRP') {
                                                    setDescripcion('Transporte');
                                                }
                                                else if (value !== null && value.split(': ')[0] === 'BNS') {
                                                    setDescripcion('Bonos Presencial');
                                                }
                                                else if (value !== null && value.split(': ')[0] === 'RCS') {
                                                    setDescripcion('Recargas');
                                                }
                                                else if (value !== null && value.split(': ')[0] === 'IMP') {
                                                    setDescripcion('Impresiones');
                                                }
                                                else {
                                                    setDescripcion('');
                                                }
                                                setDescripcionError(false);
                                                setTarifa('');
                                                setTarifaError(false);
                                                setRemunerada(false);
                                                setNocturna(false);
                                                if (value !== null) {
                                                    setIdActividad(value.split(': ')[0]);
                                                }
                                                else {
                                                    setIdActividad('');
                                                }
                                            }}
                                            options={
                                                actividades.map(option => option.codigo + ': ' + option.tipo)
                                            }
                                            // getOptionDisabled={(option) =>
                                            //     option.split(': ')[0] === 'IMP' 
                                            // }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xs={0}>
                                        <FormControlLabel
                                            style={{ marginRight: 0, marginLeft: -6, marginTop: -1, marginBottom: -1 }}
                                            control={<Checkbox color='primary' checked={nocturna} onChange={() => setNocturna(!nocturna)} />}
                                            label='Nocturna'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {idActividad === 'CCI' || idActividad === 'ECP' || idActividad === 'BNS' || idActividad === 'RCS' || idActividad === 'IMP' ?
                                <Grid item sm={4} xs={12}>
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        size='small'
                                        variant='outlined'
                                        currencySymbol='$'
                                        outputFormat='string'
                                        minimumValue='0'
                                        maximumValue='200000'
                                        decimalPlaces={0}
                                        fullWidth
                                        placeholder={idActividad === 'CCI' || idActividad === 'ECP' ? 'Valor encuesta' : 'Tarifa día'}
                                        onChange={(e, v) => { setTarifa(v); setTarifaError(false) }}
                                        error={tarifaError}
                                        value={tarifa}
                                    />
                                </Grid>
                                :
                                idActividad === 'TRP' ?
                                <Grid item sm={4} xs={12}>
                                    <CurrencyTextField
                                        modifyValueOnWheel={false}
                                        size='small'
                                        variant='outlined'
                                        currencySymbol='$'
                                        outputFormat='number'
                                        minimumValue='0'
                                        maximumValue='200000'
                                        decimalPlaces={0}
                                        fullWidth
                                        placeholder={idActividad === 'CCI' || idActividad === 'ECP' ? 'Valor encuesta' : 'Tarifa día'}
                                        onChange={(e, v) => { setTarifa(v); setTarifaError(false); v > 5900 ? setIsJustificationRequired(true) : setIsJustificationRequired(false); }}                                        error={tarifaError}
                                        value={tarifa}
                                    />
                                    {isJustificationRequired && (
                                        <div>
                                        <textarea
                                            value={justificacion}
                                            onChange={(event) => setJustificacion(event.target.value)}
                                            placeholder="Ingrese su justificación aquí"
                                            required
                                        />
                                        
                                        {justificacion.length < 20 && (
                                        <div style={{ color: 'red' }}>
                                        La justificación debe tener al menos 20 caracteres.
                                        </div>
                                    )}
                                    </div>
                                    )}
                                </Grid>
                                :


                                idActividad === 'COM' ?
                                    <Grid item>
                                        <Tooltip
                                            title={
                                                <Typography align='center' variant='body2'>Recuerda: el compensatorio solo se debe registrar cuando el encuestador ha completado mas de 48 horas en la semana y además tiene registradas 8 horas o mas adicionales el domingo</Typography>
                                            }
                                            arrow
                                            onOpen={() => setOpenTooltip(true)}
                                            onClose={() => setOpenTooltip(false)}
                                            open={openTooltip}
                                            leaveTouchDelay={10000}
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                classes={{ root: classes.button, disabled: classes.buttonDisabled }}
                                                onClick={() => setOpenTooltip(true)}
                                                style={{ height: 40, width: 40 }}
                                            >
                                                <HelpIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    :
                                    idActividad === 'NOV' ?
                                        <React.Fragment>
                                            <Grid item xs>
                                                <TextField
                                                    error={descripcionError}
                                                    value={descripcion}
                                                    autoFocus
                                                    fullWidth
                                                    size='small'
                                                    variant='outlined'
                                                    placeholder='Descripción'
                                                    onChange={(e) => { setDescripcion(e.target.value); setDescripcionError(false) }}
                                                />
                                            </Grid>
                                            <Grid item xs={0}>
                                                <FormControlLabel
                                                    style={{ marginRight: 0, marginLeft: -6, marginTop: -1, marginBottom: -1 }}
                                                    control={<Checkbox color='primary' checked={remunerada} onChange={() => setRemunerada(!remunerada)} />}
                                                    label='Remunerada'
                                                />
                                            </Grid>
                                        </React.Fragment>
                                        :
                                        idActividad === 'XX' ?
                                            <React.Fragment>
                                                <Grid item sm={5} xs={7}>
                                                    <TextField
                                                        error={descripcionError}
                                                        value={descripcion}
                                                        autoFocus
                                                        fullWidth
                                                        size='small'
                                                        variant='outlined'
                                                        placeholder='Descripción'
                                                        onChange={(e) => { setDescripcion(e.target.value); setDescripcionError(false) }}
                                                    />
                                                </Grid>
                                                <Grid item sm={3} xs={5}>
                                                    <CurrencyTextField
                                                        modifyValueOnWheel={false}
                                                        size='small'
                                                        variant='outlined'
                                                        currencySymbol='$'
                                                        outputFormat='string'
                                                        minimumValue='0'
                                                        maximumValue='200000'
                                                        decimalPlaces={0}
                                                        fullWidth
                                                        placeholder='Tarifa día'
                                                        onChange={(e, v) => { setTarifa(v); setTarifaError(false) }}
                                                        error={tarifaError}
                                                        value={tarifa}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                            :
                                            null
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                            disabled={encuestador.length === 0 && !estudio && !actividad}
                            style={{ marginTop: point === 'xs' && idActividad === 'XX' ? theme.spacing(9) : theme.spacing(3) }}
                            variant='contained' color='secondary'
                            onClick={() => { limpiar() }}
                        >
                            Limpiar
                        </Button>
                    </Grid>
                </Grid>
                {
                    registrar ?
                        <div style={{ marginTop: theme.spacing(3) }} className='Calendario'>
                            <Calendario
                                theme={theme}
                                username={username}
                                actividad={actividad}
                                encuestador={encuestador}
                                estudio={estudio}
                                eventos={[eventos, setEventos]}
                                mes={mes}
                                customData={customData}
                                setMes={setMes}
                                setAnio={setAnio}
                                sendEvento={sendEvento}
                                changeEvento={changeEvento}
                                deleteEvento={deleteEvento}
                                setSnack={setSnack}
                                setMessage={setMessage}
                                setSeverity={setSeverity}
                            />
                        </div>
                        :
                        null
                }
            </CardContent>
        </Card>

    );
}

export default Reporte;