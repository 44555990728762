import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography
} from '@material-ui/core';

function DialogoRegistro(props) {
    const theme = props.theme;
    const open = props.open;
    const setOpen = props.setOpen;
    const addEvent = props.addEvent;
    const [horas, setHoras] = useState('');
    const actividadId = props.actividadId;

    const closeDialog = () => {
        addEvent(horas);
        setOpen(false);
        setHoras('')
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle maxWidth='xs'>
                <Typography variant='h6'>Adicionar {actividadId !== null && (actividadId === 'ECP' || actividadId === 'ECP2') ? ' encuestas' : ' horas'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1'>Adicione la cantidad de {actividadId !== null && (actividadId === 'ECP' || actividadId === 'ECP2') ? ' encuestas ' : ' horas '} de la actividad seleccionada.</Typography>
                <TextField
                    autoFocus={true}
                    style={{ marginTop: theme.spacing(2) }}
                    size='small'
                    variant='outlined'
                    label={actividadId !== null && (actividadId === 'ECP' || actividadId === 'ECP2') ? 'Encuestas' : 'Horas'}
                    fullWidth
                    value={horas}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter')
                            closeDialog();
                    }}
                    onChange={(e) => {
                        if (actividadId !== null && (actividadId === 'ECP' || actividadId === 'ECP2')) {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            setHoras(e.target.value);
                        }
                        else if (actividadId === 'PROD') {
                            e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                            setHoras(e.target.value);
                            
                        }
                        else {
                            var split = ''
                            if (e.target.value.includes('.')) {
                                split = e.target.value.split('.')
                                if (split[1].length < 2 && parseInt(split[0]) < 15) {
                                    e.target.value = split[0] + '.' + split[1].replace(/[^5]/g, '');
                                    setHoras(e.target.value);
                                }
                            }
                            else {
                                e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                if (e.target.value === '' || parseInt(e.target.value) <= 15) {
                                    setHoras(e.target.value);
                                }
                            }
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => { setOpen(false); setHoras('') }} >
                    Cancelar
                </Button>
                <Button color='primary' onClick={() => closeDialog()}>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoRegistro;