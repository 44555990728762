import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Popover,
    Button,
    Collapse,
    Divider
} from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/calendar-font.css';
import '../../../styles/hide_popup.css';
import moment from 'moment';
import { format } from 'date-fns';
import EsLocale from 'date-fns/locale/es';

import DialogoRegistro from './DialogoRegistro';
import DialogoEditar from './DialogoEditar';
import actividades from '../../../data/actividades';

require('moment/locale/es');
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(() => ({
    btn: {
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#a6a6a6'
        },
        '&:disabled:hover': {
            backgroundColor: '#e0e0e0',
            border: '1px solid #ccc',
            color: '#a6a6a6'
        }
    }
}));

function hexToRgb(hex) {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}


function luminance(r, g, b) {
    var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function isUserInCustomData(userx, customData, inicio) {
    console.log(userx, customData, inicio)

    // Convierte la fecha de inicio a un objeto Date
    const inicioDate = new Date(inicio);
    
    for (var i = 0; i < customData.length; i++) {
        var usernames = customData[i].username;
    
        for (var j = 0; j < usernames.length; j++) {

        if (usernames[j] === userx) {
            // Convierte la fecha de customData[i].start_edition_date a un objeto Date
            const startEditionDate = new Date(customData[i].start_edition_date);
    
            if (startEditionDate <= inicioDate) {
            return true;
            }
        }
        }
    }
    
    return false;
    }

function Calendario(props) {
    const theme = props.theme;
    const username = props.username;
    const actividad = props.actividad;
    const classes = useStyles();
    const [eventos, setEventos] = props.eventos;
    const sendEvento = props.sendEvento;
    const changeEvento = props.changeEvento;
    const deleteEvento = props.deleteEvento;
    const setSnack = props.setSnack;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;
    const mes = props.mes;
    const setMes = props.setMes;
    const setAnio = props.setAnio;
    const encuestador = props.encuestador;
    const estudio = props.estudio;
    const customData = props.customData;

    const [inicioEvento, setInicioEvento] = useState(null);
    const [finEvento, setFinEvento] = useState(null);
    const [heightCalendar, setHeightCalendar] = useState(weekHeight((new Date()).getFullYear(), (new Date()).getMonth() + 1));
    const [openDialogoRegistro, setOpenDialogoRegistro] = useState(false);
    const [fechaPopup, setFechaPopup] = useState(null);
    const [eventosPopup, setEventosPopup] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [openPopoverPopup, setOpenPopoverPopup] = useState(false);
    const [openPopoverEvento, setOpenPopoverEvento] = useState(false);
    const [openPopoverActividad, setOpenPopoverActividad] = useState(false);
    const [idEvento, setIdEvento] = useState(0);
    const [eventoActual, setEventoActual] = useState(null);
    const [eventoActual2, setEventoActual2] = useState(null);
    const [openDialogoEditar, setOpenDialogoEditar] = useState(false);

    const [encuestadorActual, setEncuestadorActual] = useState(null);

    const [fechaInicioEvento, setFechaInicioEvento] = useState(null);


    let fechaHabilitarReporte = new Date();
    const numeroDia = fechaHabilitarReporte.getDay();
        if (numeroDia === 1) {
            fechaHabilitarReporte.setDate(fechaHabilitarReporte.getDate() - 3);
        }
        else if (numeroDia === 0) {
            fechaHabilitarReporte.setDate(fechaHabilitarReporte.getDate() - 2);
        }
        else {
            fechaHabilitarReporte.setDate(fechaHabilitarReporte.getDate() - 1);
        }
    fechaHabilitarReporte.setHours(0, 0, 0, 0);

    const openInputDialog = ({ start, end }) => {
        
        let inicio = new Date(start.toISOString().split('T')[0])
        let fin = new Date(end.toISOString().split('T')[0])
        let actual = new Date(new Date().toISOString().split('T')[0])
        inicio.setDate(inicio.getDate() + 1)
        fin.setDate(fin.getDate() + 1)
        actual.setDate(actual.getDate() + 1)

        if (inicio <= actual && fin <= actual && inicio >= fechaHabilitarReporte && fin >= fechaHabilitarReporte && inicio.getMonth() + 1 === mes && fin.getMonth() + 1 === mes) {
            var fechas = [];
            console.log("Me fui por el primer if")
            var eventoActual = new Date(start);
            var eventoFinal = new Date(end);
            eventoFinal.setTime(eventoFinal.getTime() + 1);
            while (eventoActual <= eventoFinal) {
                fechas.push(eventoActual.toISOString().split('T')[0] + ' 00:00:00')
                eventoActual.setDate(eventoActual.getDate() + 1);
            }
            if (eventos.filter(e => e.title === estudio).map(evento => [evento.start, encuestador.length === 1 ? evento.actividades[encuestador] : [].concat.apply([], evento.encuestadores.map(e => evento.actividades[e]))]).some(v => fechas.includes(v[0]) && v[1].map(a => a.actividad).includes(actividad.split(':')[0]))) {
                setMessage('No se pueden agregar horas de una misma actividad a una fecha que ya tiene.');
                setSeverity('error');
                setSnack(true);
            }
            else if (new Date(start).getMonth() === mes - 1) {
                setOpenDialogoRegistro(true);
                setInicioEvento(new Date(start));
                setFinEvento(eventoFinal);
            }
        }
        //ON DEBUG
        else if (inicio <= actual && fin <= actual && inicio.getMonth() + 1 === mes && fin.getMonth() + 1 === mes && isUserInCustomData(username, customData, inicio)) {
            var fechas = [];
            var eventoActual = new Date(start);
            var eventoFinal = new Date(end);
            eventoFinal.setTime(eventoFinal.getTime() + 1);
            while (eventoActual <= eventoFinal) {
                fechas.push(eventoActual.toISOString().split('T')[0] + ' 00:00:00')
                eventoActual.setDate(eventoActual.getDate() + 1);
            }
            if (eventos.filter(e => e.title === estudio).map(evento => [evento.start, encuestador.length === 1 ? evento.actividades[encuestador] : [].concat.apply([], evento.encuestadores.map(e => evento.actividades[e]))]).some(v => fechas.includes(v[0]) && v[1].map(a => a.actividad).includes(actividad.split(':')[0]))) {
                setMessage('No se pueden agregar horas de una misma actividad a una fecha que ya tiene.');
                setSeverity('error');
                setSnack(true);
            }
            else if (new Date(start).getMonth() === mes - 1) {
                setOpenDialogoRegistro(true);
                setInicioEvento(new Date(start));
                setFinEvento(eventoFinal);
            }
        }
    };

    class CustomToolbar extends Toolbar {
        render() {
            return (
                <div className='rbc-toolbar'>
                    <span className="rbc-btn-group">
                        <button type="button" onClick={() => this.navigate('TODAY')}>Hoy</button>
                        <button type="button" onClick={() => this.navigate('PREV')}>&lt;&lt;</button>
                        <button type="button" className={classes.btn} disabled={new Date().getMonth() + 1 === mes} onClick={() => this.navigate('NEXT')}>&gt;&gt;</button>
                    </span>
                    <span className="rbc-toolbar-label">{this.props.label}</span>
                </div>
            );
        }

        navigate = action => {
            this.props.onNavigate(action)
        }
    }

    function addEvent(horas) {
        var eventoActual = inicioEvento;
        if (horas !== '' && horas !== '0') {
            while (eventoActual <= finEvento) {
                sendEvento(eventoActual.toISOString().split('T')[0], parseFloat(horas))
                eventoActual.setDate(eventoActual.getDate() + 1);
            }
        }
        else {
            setMessage(`Para crear un evento la cantidad de ${actividad !== null && (actividad.split(': ')[0] === 'ECP' || actividad.split(': ')[0] === 'ECP2') ? 'encuestas' : 'horas'} debe ser mayor a cero o no estar vacía.`)
            setSeverity('warning');
            setSnack(true);
        }
    }

    function editEvento(horas) {
        if (horas !== '' && horas !== '0') {
            closePopoverEvento();
            setOpenPopoverPopup(false); setTimeout(() => { setEventosPopup(null); setFechaPopup(null) }, 150);
            changeEvento(idEvento, parseFloat(horas));
            setIdEvento(0);
            setEventoActual(null);
        }
    }

    // function editEvento(horas, inicio, fin, fechaHabilitarReporte) {
    //     if (horas !== '' && horas !== '0') {
    //         // Verifica si inicio y fin están después o igual a fechaHabilitarReporte
    //         if (inicio >= fechaHabilitarReporte && fin >= fechaHabilitarReporte) {
    //             closePopoverEvento();
    //             setOpenPopoverEvento(false);
    //             setTimeout(() => { setEventosPopup(null); setFechaPopup(null) }, 150);
    //             changeEvento(idEvento, parseFloat(horas));
    //             setIdEvento(0);
    //             setEventoActual(null);
    //         } else {
    //             // Muestra un mensaje de error al usuario
    //             setMessage('No se puede editar el evento en una fecha posterior a la fecha límite.');
    //             setSeverity('error');
    //             setSnack(true);
    //         }
    //     } else {
    //         setMessage(`Para editar el evento, la cantidad de ${actividad !== null && (actividad.split(': ')[0] === 'ECP' || actividad.split(': ')[0] === 'ECP2') ? 'encuestas' : 'horas'} debe ser mayor a cero o no estar vacía.`)
    //         setSeverity('warning');
    //         setSnack(true);
    //     }
    // }

    function weekHeight(year, month_number) {
        var firstOfMonth = new Date(year, month_number - 1, 1);
        var day = firstOfMonth.getDay() || 6;
        day = day === 1 ? 0 : day;
        if (day) { day-- }
        var diff = 7 - day;
        var lastOfMonth = new Date(year, month_number, 0);
        var lastDate = lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 1) {
            diff--;
        }
        var result = Math.ceil((lastDate - diff) / 7);
        return result + 1 === 4 ? 581.5 : result + 1 === 5 ? 710 : 838.48;
    };

    const getColor = (e) => {
        const colorHex = e.creador !== undefined ? (e.id.length === undefined && e.creador === username) || (e.id.length !== undefined && !e.creador.some(v => v !== username)) ? actividades.filter(a => a.codigo === e.actividad)[0].color : actividades.filter(a => a.codigo === e.actividad)[0].color2 : '#424242'
        const colorRgb = hexToRgb(colorHex);
        const luminanceColor = luminance(colorRgb.r, colorRgb.g, colorRgb.b);
        const luminanceColorW = luminance(255, 255, 255);
        const luminanceColorB = luminance(0, 0, 0);
        const ratioW = luminanceColor > luminanceColorW ? ((luminanceColorW + 0.05) / (luminanceColor + 0.05)) : ((luminanceColor + 0.05) / (luminanceColorW + 0.05));
        const ratioB = luminanceColor > luminanceColorB ? ((luminanceColorB + 0.05) / (luminanceColor + 0.05)) : ((luminanceColor + 0.05) / (luminanceColorB + 0.05));
        return [colorHex, ratioW < ratioB ? 'white' : 'black']
    }

    const closePopoverEvento = () => {
        setOpenPopoverEvento(false);
        setTimeout(() => {
            setEventoActual2(null);
            setEncuestadorActual(null)
        }, 150);
    }

    const currency = function(number){
        return new Intl.NumberFormat('es-co', {style: 'currency',currency: 'COP', minimumFractionDigits:0}).format(number);
    };

    return (
        <React.Fragment>
            <Calendar
                components={{ toolbar: CustomToolbar }}
                popup
                selectable
                style={{ height: heightCalendar }}
                localizer={localizer}
                view='month'
                views={['month']}
                messages={{
                    showMore: (target) => <Typography variant='subtitle2' onClick={(e) => setAnchorEl3(e.currentTarget)}> ...{target} más</Typography>,
                    next: '>>',
                    previous: '<<',
                    today: 'Hoy'
                }}
                onShowMore={(e, d) => { setEventosPopup(e); setFechaPopup(d); setOpenPopoverPopup(true); }}
                onRangeChange={({ start }) => {
                    var date = new Date(start);
                    date.setDate(date.getDate() + 8);
                    setMes((new Date(date)).getMonth() + 1);
                    setAnio((new Date(date)).getFullYear());
                    setHeightCalendar(weekHeight((new Date(date)).getFullYear(), (new Date(date)).getMonth() + 1));
                    setEventos([]);
                }}
                events={eventos}
                startAccessor='start'
                endAccessor='end'
                onSelectSlot={openInputDialog}
                onSelectEvent={(event, target) => {
                    setEventoActual2(eventos.filter(e => e.id === event.id)[0]);
                    setFechaInicioEvento(new Date(event.start)); // Almacenar la fecha de inicio del evento seleccionado
                    setOpenPopoverEvento(true);
                    setAnchorEl2(target.currentTarget);
                }}
                dayPropGetter={(date) => 
                    {let fechaHabilitarReporte = new Date(); 
                        if (fechaHabilitarReporte.getDay() === 1) 
                        {fechaHabilitarReporte.setDate(fechaHabilitarReporte.getDate() - 3); } 
                        else if (fechaHabilitarReporte.getDay() === 0) 
                        { fechaHabilitarReporte.setDate(fechaHabilitarReporte.getDate() - 2); } 
                        else 
                        { fechaHabilitarReporte.setDate(fechaHabilitarReporte.getDate() - 1);}
                        fechaHabilitarReporte.setHours(0, 0, 0, 0);
                        if (date > new Date() || date < fechaHabilitarReporte || date.getMonth() + 1 !== mes) 
                        {return { style: { background: '#E6E6E6' } };}}}                
                eventPropGetter={(e) => {

                    return {
                        style: { backgroundColor: eventoActual2 !== null ? eventoActual2 === e ? e.color : e.color + '66' : e.color, color: e.color2 }
                    }
                }}
            />
            {eventosPopup !== null && fechaPopup !== null ?
                <Popover
                    open={openPopoverPopup}
                    onClose={() => { setOpenPopoverPopup(false); setTimeout(() => { setEventosPopup(null); setFechaPopup(null) }, 150); }}
                    anchorEl={anchorEl3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div style={{ width: 250, margin: theme.spacing(1) }}>
                        <span>{format(fechaPopup, 'EEEE dd, MMM', { locale: EsLocale })}</span>
                        <Divider style={{ marginBottom: theme.spacing(0.5) }} />
                        {eventosPopup.map(e =>
                            <div style={{ marginBottom: 1 }}>
                                <div
                                    style={{ borderRadius: 5, backgroundColor: eventoActual2 !== null ? eventoActual2 === e ? e.color : e.color + '66' : e.color, color: e.color2, padding: '2px 5px', cursor: 'pointer' }}
                                    onClick={(evento) => {
                                        setEventoActual2(eventos.filter(ev => ev.id === e.id)[0]);
                                        setOpenPopoverEvento(true);
                                        setAnchorEl2(evento.currentTarget);
                                    }}
                                >
                                    {e.title}
                                </div>
                            </div>
                        )}
                    </div>
                </Popover>
                :
                null
            }
            {
                eventoActual2 !== null ?
                    <Popover
                        open={openPopoverEvento && eventoActual2 !== null}
                        onClose={() => { closePopoverEvento() }}
                        anchorEl={anchorEl2}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div style={{ width: encuestador.length === 1 ? 200 : 220, maxHeight: 300, overflowY: 'auto', margin: theme.spacing(1) }}>
                            {encuestador.length === 1 ?
                                eventoActual2.actividades[encuestador].map(a =>
                                    <div style={{ marginBottom: 2 }}>
                                        <div
                                            style={{ borderRadius: 5, background: getColor(a)[0], color: getColor(a)[1], padding: '2px 5px', cursor: a.title.includes('encuesta') && !a.title.includes('ECP') && !a.title.includes('ECP2') ? null : 'pointer' }}
                                            onClick={(event) => { setAnchorEl(event.currentTarget); setOpenPopoverActividad(true); setIdEvento(a.id); setEventoActual(a) }}
                                        >
                                            {a.title}
                                            <br></br>
                                            {a.valor !== undefined?
                                                <span>Valor: {currency(a.valor)}</span>
                                            : 
                                                null
                                            }
                                        </div>
                                    </div>
                                )
                                :
                                eventoActual2.encuestadores.map(e => (
                                    <React.Fragment>
                                        <div style={{ marginBottom: 2 }}>
                                            <div onClick={() => { encuestadorActual === e ? setEncuestadorActual(null) : setEncuestadorActual(e) }} style={{ height: 26.4, background: encuestadorActual === e ? eventoActual2.color + 'b3' : null, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
                                                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', borderRadius: 5, background: encuestadorActual !== null ? encuestadorActual === e ? eventoActual2.color : eventoActual2.color + '66' : eventoActual2.color, color: eventoActual2.color2, cursor: 'pointer', padding: '2px 5px' }}>
                                                    {e}
                                                </div>
                                            </div>
                                            <Collapse in={encuestadorActual === e}>
                                                <div style={{ background: eventoActual2.color + 'b3', paddingTop: 3, paddingBottom: 3, marginBottom: 1, borderBottomRightRadius: 5, borderBottomLeftRadius: 5 }}>
                                                    <div style={{ marginLeft: theme.spacing(1.5), marginRight: theme.spacing(1) }}>
                                                        {eventoActual2.actividades[e].map(a => (
                                                            <div style={{ height: 26.4, width: '100%', marginBottom: 1 }}>
                                                                <div
                                                                    style={{ borderRadius: 5, background: getColor(a)[0], color: getColor(a)[1], padding: '2px 5px', cursor: a.title.includes('encuesta') && !a.title.includes('ECP') && !a.title.includes('ECP2') ? null : 'pointer' }}
                                                                    onClick={(event) => { setAnchorEl(event.currentTarget); setOpenPopoverActividad(true); setIdEvento(a.id); setEventoActual(a) }}
                                                                >
                                                                    {a.title}
                                                                    <br></br>
                                                                    {a.valor !== undefined?
                                                                        <span>Valor: {currency(a.valor)}</span>
                                                                    : 
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    </React.Fragment>
                                ))}
                        </div>
                    </Popover>
                    :
                    null
            }
            <DialogoRegistro
                theme={theme}
                open={openDialogoRegistro}
                setOpen={setOpenDialogoRegistro}
                addEvent={addEvent}
                actividadId={actividad !== null ? actividad.split(': ')[0] : null}
            />
            <Popover
                open={openPopoverActividad && idEvento !== undefined && eventoActual.creador !== undefined}
                onClose={() => { setOpenPopoverActividad(false) }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    eventoActual !== null && eventoActual !== undefined && idEvento !== undefined && idEvento !== 0 ?
                        
                        (idEvento.length === undefined && eventoActual.creador !== username) || (idEvento.length !== undefined && eventoActual.creador.some(v => v !== username)) ?
                            (idEvento.length === undefined && eventoActual.creador !== username) ?
                                
                                <Typography style={{ margin: theme.spacing(1) }} variant='body2'><span style={{ fontWeight: 500, fontSize: 'inherit' }}>Agregado por:</span> {eventoActual.creador}</Typography>
                                :
                                <div style={{ margin: theme.spacing(1) }} >
                                    <Typography style={{ marginBottom: theme.spacing(0.2) }} variant='body2'><span style={{ fontWeight: 500, fontSize: 'inherit' }}>Existen horas agregadas por:</span></Typography>
                                    {eventoActual.creador.map(c => (
                                        <Typography variant='body2'>&#8226; {c}</Typography>
                                    ))}
                                </div>
                            :
                            fechaInicioEvento >= fechaHabilitarReporte || isUserInCustomData(username, customData, fechaInicioEvento) ? // Usar fechaInicioEvento para la condición

                            <React.Fragment>
                                <Button style={{ margin: theme.spacing(1) }} color='primary' variant='outlined' onClick={() => { setAnchorEl(null); setOpenPopoverActividad(false); setOpenDialogoEditar(true); }}>
                                    <Typography variant='subtitle2'>Editar</Typography>
                                </Button>
                                <Button style={{ marginRight: theme.spacing(1) }} color='secondary' variant='outlined' onClick={() => { closePopoverEvento(); setOpenPopoverPopup(false); setTimeout(() => { setEventosPopup(null); setFechaPopup(null) }, 150); deleteEvento(idEvento); setAnchorEl(null); setOpenPopoverActividad(false); setIdEvento(0); setEventoActual(null) }} >
                                    <Typography variant='subtitle2'>Eliminar</Typography>
                                </Button>
                            </React.Fragment>
                        :
                        null
                        :
                        null
                        
                }
            
            </Popover>
            <DialogoEditar
                theme={theme}
                open={openDialogoEditar}
                setOpen={setOpenDialogoEditar}
                editEvento={editEvento}
                eventoActual={eventoActual}
            />
        </React.Fragment >
    );
}

export default Calendario;
