import React, { useState, useRef } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField,
    Divider,
    Chip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

function DialogoCrear(props) {
    const { theme, 
        getAccessTokenWithRefresh, 
        setMessage, 
        setSeverity, 
        setSnack, 
        setShowBackdrop, 
        API_DEFAULT, 
        fetchUsuarios 
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
    const [encuestadores, setEncuestadores] = useState([]);
    const [openList, setOpenList] = useState(false);
    const [encuestador, setEncuestador] = useState([]);

    const [searching, setSearching] = useState(false);
    const loading = open && encuestadores.length === 0 && searching;
    let controller = new AbortController();
    let timeout;
    const point = props.point;
    let [inputValue] = React.useState('');
    const refEnc = useRef(null); 

    const fetchEncuestadores = async (access = accessToken) => {
        if (inputValue.length > 0) {
            setSearching(true);
            const res = await fetch(`${API_DEFAULT}/legalizaciones/no_usuarios_legalizaciones/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal ,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if(res !== undefined){
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadores(newAccess);
                        }
    
                    } else {
                        setEncuestadores(res.sort((first, second) => (first['cedula'] - second['cedula'])));
                        setShowBackdrop(false);
                    }
                });
            }else{
                setEncuestadores([]);
                setSearching(false);
            }
        } else {
            setSearching(false);
            setEncuestadores([]);
        }
    }

    const limpiar = () => {
        setOpen(false);
        setEncuestador([]);
        setEncuestadores([]);
    }

    const putInformacion = async (access = accessToken) => {
        setShowBackdrop(true);
        let cedulas = []
        let nombres = []

        for (var i = 0; i < encuestador.length; i++) {
            cedulas.push(encuestador[i].split(': ')[0])
            nombres.push(encuestador[i].split(': ')[1])
        }
        const res = await fetch(`${API_DEFAULT}/legalizaciones/usuarios_legalizaciones/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify(
                {
                    'cedula': cedulas,
                    'nombre': nombres
                }
            )
        });
        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se han creado los usuarios exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            fetchUsuarios(access);
            limpiar()
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformacion(newAccess);
                    } 
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
        //
    }

    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchEncuestadores();
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Agregar Usuarios</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant='body1'>Seleccione el(los) encuestador(es):</Typography>
                <Autocomplete
                    multiple
                    open={openList}
                    onFocus={() => !open ? setOpen(true) : null}
                    onOpen={() => { if (!open) setOpen(true); if (!openList) setOpenList(true) }}
                    onBlur={() => setOpenList(false)}
                    limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                    size='small'
                    value={encuestador}
                    loading={loading}
                    onChange={(event, value) => {
                        setEncuestador(value);
                    }}
                    options={
                        encuestadores.map(option => '' + option.cedula + ': ' + option.nombre)
                    }
                    noOptionsText='No hay coincidencias'
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                style={{ overflow: 'hidden' }}
                                variant="outlined"
                                label={option}
                                size="small"
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={params => {
                        const { inputProps, InputProps, ...restParams } = params;
                        const { startAdornment, ...restInputProps } = InputProps;

                        return (
                            <TextField
                                {...restParams}
                                size="small"
                                value=""
                                InputProps={{
                                    onKeyDown: (e) => {
                                        if (e.key === 'ArrowLeft') {
                                            e.stopPropagation()
                                        } else {
                                            e.persist();
                                            clearTimeout(timeout);

                                            timeout = setTimeout(() => {
                                                inputValue = e.target.value;
                                                handleChange();
                                                fetchEncuestadores();
                                                clearTimeout(timeout);
                                            }, 700);
                                        }
                                    },
                                    ...restInputProps,
                                    startAdornment: (
                                        <div ref={refEnc} onClick={() => { if (!openList) setOpenList(true); if (!open) setOpen(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: open ? 'auto' : null, display: open ? null : 'flex', flexDirection: open ? null : 'row' }}>
                                            {startAdornment}
                                        </div>
                                    ),
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),

                                }}
                                inputProps={{ ...inputProps, style: { position: open || encuestador.length === 0 ? null : 'absolute', width: 'calc(100% - 20px)', zIndex: open || encuestador.length === 0 ? null : -1 } }}
                                variant="outlined"
                                autoFocus={true}
                                fullWidth
                            />

                        )
                    }}
                />
            </DialogContent>
            <Divider />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() =>  putInformacion()}>
                    Aceptar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => { limpiar() }} >
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoCrear;
